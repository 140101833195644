import { XMarkIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { Children, useEffect, useRef, useState } from "react";
import { Filter, SortDownAlt, SortUpAlt } from "react-bootstrap-icons";
import Skeleton from "react-loading-skeleton";
import constants from "../../utils/constants";
import { classNames } from "../../utils/helpers";
import { Paginator } from "../Pagination";

function PrimaryTable({
  header,
  children,
  isLoading,
  totalCount,
  displayCount,
  currentPage,
  setCurrentPage,
  setDisplayCount,
  filters,
}) {
  const columnCount = Children.count(header.props.children);

  return (
    <div className="relative  shadow-md border-none">
      <table className="min-w-full border-none table-fixed">
        {header}
        <tbody>
          <tr></tr>
          {isLoading ? (
            _.times(5, (j) => (
              <PrimaryTable.Row key={`row-${j}`}>
                {_.times(columnCount, (i) => (
                  <PrimaryTable.Cell key={i}>
                    <Skeleton height="25px" />
                  </PrimaryTable.Cell>
                ))}
              </PrimaryTable.Row>
            ))
          ) : (
            <>
              {children}
              {totalCount ? (
                <PrimaryTable.Row>
                  <td colSpan={columnCount}>
                    <Paginator
                      count={totalCount}
                      display={displayCount}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      setDisplay={setDisplayCount}
                      filters={filters}
                    />
                  </td>
                </PrimaryTable.Row>
              ) : null}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

function Row({ children, index, onClick }) {
  return (
    <tr
      key={`row-${index}`}
      className={classNames(
        "hover:bg-gray-100 cursor-pointer",
        index % 2 === 0 ? "" : "bg-gray-50"
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  );
}

function Cell({ className, children, replaceClassName, paddingTop = "pt-3", paddingBottom = "pb-3" }) {
  const classes = replaceClassName
    ? replaceClassName
    : classNames("px-6 whitespace-nowrap text-sm font-medium text-tempest border border-gray-100", paddingTop, paddingBottom, className);
  return (
    <td className={classes}>
      {children}
    </td>
  );
}

function Header({ children }) {
  return (
    <thead className="bg-white">
      <tr className="">{children}</tr>
    </thead>
  );
}

function HeaderColumn({
  display,
  onType,
  value,
  isSortingBy,
  onSort,
  colSpan,
  onClear,
  sortDirection,
  width = "w-72"
}) {
  const [isTyping, setIsTyping] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isTyping && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isTyping]);

  return (
    <th
      scope="col"
      className={classNames("px-6 py-4 text-left text-sm font-medium text-gray-700 uppercase tracking-wider border", width)}
      onBlur={() => {
        if (!value) setIsTyping(false);
      }}
      colSpan={colSpan || 1}
      onClick={() => {
        if (!onType) return;
        setIsTyping(true);
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <div className="flex items-center">
        <div className="flex items-center">
          <div className="mr-1 cursor-pointer text-cardinalRed hover:text-sandyOrange">
            {onSort &&
              (isSortingBy ? (
                sortDirection === constants.SORT_DIRECTIONS.ASCENDING ? (
                  <SortDownAlt
                    title="Sort ascending"
                    size={18}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSort();
                    }}
                  />
                ) : (
                  <SortUpAlt
                    title="Sort descending"
                    size={18}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSort();
                    }}
                  />
                )
              ) : (
                <Filter
                  title={`Sort by ${display}`}
                  size={18}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSort();
                  }}
                />
              ))}
          </div>
          {value || isTyping ? (
            <ColumnFilter
              placeholder={display}
              value={value}
              onType={onType}
              inputRef={inputRef}
              onClear={onClear}
            />
          ) : (
            display
          )}
        </div>
      </div>
    </th>
  );
}

const ColumnFilter = ({ placeholder, value, onType, inputRef, onClear }) => {
  return (
    <div className="relative">
      <input
        ref={inputRef}
        className="focus:outline-none w-full uppercase  sm:text-sm placeholder-gray-300 text-primaryColor"
        placeholder={placeholder}
        value={value || ""}
        onChange={onType}
      />
      {value && (
        <XMarkIcon
          className="h-4 w-4 text-gray-400 absolute right-0 mr-2 my-auto inset-y-0 cursor-pointer"
          onClick={onClear}
        />
      )}
    </div>
  );
};

PrimaryTable.Row = Row;
PrimaryTable.Cell = Cell;
PrimaryTable.Header = Header;
PrimaryTable.HeaderColumn = HeaderColumn;

export default PrimaryTable;
